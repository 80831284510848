<script lang="ts">
    import axios from 'axios';
    import { getContext } from 'svelte';
    import toast from 'svelte-french-toast';
    import { Button } from '$lib/components/ui/button';
    import { Input } from '$lib/components/ui/input';
    import Label from '$lib/components/ui/label/label.svelte';
    import * as Select from '$lib/components/ui/select/index.js';
    import * as Dialog from '$lib/components/ui/dialog';
    import { Calendar } from '$lib/components/ui/calendar';
    import { CalendarIcon } from 'lucide-svelte';
    import { cn } from '$lib/utils';
    import * as Table from '$lib/components/ui/table';
    import * as Popover from '$lib/components/ui/popover';
    import { DateFormatter, type DateValue, getLocalTimeZone, today, parseAbsoluteToLocal } from '@internationalized/date';
    import type { SettingsContext } from '@/interfaces/settings';
    import type { Referral } from '@/interfaces/general';

    interface AuthUser {
        id: number;
        username: string;
        referral_code: string;
    }

    interface SettingsData {
        referrals_default_link_page: string;
        referrals: {
            data: Referral[];
        };
    }

    interface Referral {
        id: number;
        used_by: {
            id: number;
            name: string;
            username: string;
            latest_offer?: {
                id: number;
                listing: {
                    id: number;
                    price: string;
                    expiry_time: string;
                    status: string;
                };
            };
        };
        created_at: string;
        earned?: number | string | null;
        offer?: {
            id: number;
            price: string;
            validity: string;
            status: string;
        };
    }

    const settingsContext = getContext<SettingsContext>('settingsContext');
    const authUser: AuthUser = settingsContext.authUser;
    const data: SettingsData = settingsContext.data;

    const df = new DateFormatter('en-US', { dateStyle: 'long' });

    let selectedPrice = 0;
    let selectedValidity: DateValue | undefined = undefined;
    let selectedReferral: Referral | null = null;

    function getBaseUrl(): string {
        const { protocol, hostname, port } = window.location;
        return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    }

    let newReferralCode = authUser.referral_code;
    let copyButtonText = 'Copy';
    let copyButtonTitle = 'Copy to Clipboard';

    function updateReferralCode(): void {
        const dataToSend = {
            user_id: authUser.id,
            previous_code: authUser.referral_code,
            referral_code: newReferralCode
        };

        axios.post("/api/v1/referral/update", dataToSend)
            .then(response => {
                toast.success(response.data.message);
                authUser.referral_code = newReferralCode;
            })
            .catch(error => {
                toast.error(error.response?.data?.error || "Error updating the referral code");
            });
    }

    $: referralLink = (() => {
        const baseUrl = getBaseUrl();
        switch (data.referrals_default_link_page) {
            case 'profile':
                return `${baseUrl}/${authUser.username}?ref=${authUser.referral_code}`;
            case 'home':
                return `${baseUrl}?ref=${authUser.referral_code}`;
            case 'register':
                return `${baseUrl}/register?ref=${authUser.referral_code}`;
            default:
                return '';
        }
    })();

    async function copyReferralLink(): Promise<void> {
        try {
            await navigator.clipboard.writeText(referralLink);
            toast.success('Referral link copied to clipboard');
        } catch (error) {
            console.error('Failed to copy: ', error);
            toast.error('Failed to copy referral link');
        }
    }

    function openPriceModal(referral: Referral): void {
        selectedReferral = JSON.parse(JSON.stringify(referral));

        if (referral.used_by.latest_offer?.listing?.status === 'active') {
            selectedPrice = parseFloat(referral.used_by.latest_offer.listing.price) || 0;
            selectedValidity = referral.used_by.latest_offer.listing.expiry_time
                ? parseAbsoluteToLocal(new Date(referral.used_by.latest_offer.listing.expiry_time).toISOString())
                : undefined;
        } else {
            selectedPrice = 0;
            selectedValidity = undefined;
        }
    }

    function validateForm(): string | null {
        if (selectedPrice <= 0) return 'Please enter a valid price';
        if (!selectedValidity) return 'Please select a validity date';
        return null;
    }

    let isSubmitting = false;

    async function submitPrice(): Promise<void> {
        const validationError = validateForm();
        if (validationError) {
            toast.error(validationError);
            return;
        }

        if (!selectedReferral) return;

        isSubmitting = true;

        const isUpdate = selectedReferral.used_by.latest_offer?.listing?.status === 'active';

        const dataToSend = {
            user_id: selectedReferral.used_by.id,
            price: selectedPrice,
            validity: selectedValidity!.toString(),
            actionType: isUpdate ? 'update' : 'create'
        };

        try {
            const response = await axios.post('/store-referral-user-for-sale', dataToSend);

            toast.success(response.data.message);

            const referralIndex = data.referrals.data.findIndex(
                r => r.id === selectedReferral!.id
            );

            if (referralIndex !== -1) {
                const updatedOffer = {
                    id: isUpdate && selectedReferral.used_by.latest_offer ? selectedReferral.used_by.latest_offer.id : (response.data.offerId || 0),
                    listing: {
                        id: isUpdate && selectedReferral.used_by.latest_offer ? selectedReferral.used_by.latest_offer.listing.id : (response.data.listingId || 0),
                        price: selectedPrice.toString(),
                        expiry_time: selectedValidity!.toDate(getLocalTimeZone()).toISOString(),
                        status: 'active'
                    }
                };

                const updatedReferral = { ...data.referrals.data[referralIndex] };

                updatedReferral.used_by.latest_offer = updatedOffer;

                data.referrals.data[referralIndex] = updatedReferral;
                data.referrals.data = [...data.referrals.data];
            }

            selectedReferral = null;
        } catch (error: any) {
            const errorMessage =
                error.response?.status === 422
                    ? error.response.data.message
                    : 'An unexpected error occurred. Please try again.';
            toast.error(errorMessage);
        } finally {
            isSubmitting = false;
        }
    }

    function formatDate(dateString: string): string {
        return new Date(dateString).toLocaleDateString();
    }

    function formatCurrency(amount: number | string | null | undefined): string {
        const numAmount = Number(amount);
        return isNaN(numAmount)
            ? '$0.00'
            : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(numAmount);
    }

    $: isFormValid = Number(selectedPrice) > 0 && selectedValidity !== undefined;

    function getCurrentPrice(referral: Referral): string {
        if (referral.used_by.latest_offer?.listing?.status === 'active') {
            return formatCurrency(referral.used_by.latest_offer.listing.price);
        }
        return 'Not Listed';
    }

    function getExpiryDate(referral: Referral): string {
        if (referral.used_by.latest_offer?.listing?.status === 'active') {
            return formatDate(referral.used_by.latest_offer.listing.expiry_time);
        }
        return '-';
    }
</script>

<div class="mx-auto max-w-3xl space-y-8">
    <section class="space-y-4">
        <h2 class="text-center text-2xl font-bold">Your Referral Link</h2>
        <p class="text-center text-gray-600 dark:text-gray-400">
            Copy your referral link and invite other people to get a fee from their earnings.
        </p>
        <div class="flex px-5">
            <Input type="text" class="text-center" readonly value="{referralLink}" aria-label="Your referral link" />
            <Button on:click="{copyReferralLink}" variant="default" class="ml-2 text-white">
                <span class="sr-only">Copy referral link</span>
                Copy
            </Button>
        </div>
    </section>

    <section class="space-y-4">
        <h2 class="text-center text-2xl font-bold">Your Referral List</h2>
        {#if data.referrals.data && data.referrals.data.length > 0}
            <div class="overflow-x-auto">
                <Table.Root>
                    <Table.Header>
                        <Table.Row>
                            <Table.Head>Name</Table.Head>
                            <Table.Head>Since</Table.Head>
                            <Table.Head>Earned</Table.Head>
                            <Table.Head>Price</Table.Head>
                            <Table.Head>Expiry</Table.Head>
                            <Table.Head>Action</Table.Head>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {#each data.referrals.data as referral}
                            <Table.Row>
                                <Table.Cell>
                                    <a href="/{referral.used_by.username}" class="text-bmn-600 hover:underline">
                                        {referral.used_by.name}
                                    </a>
                                </Table.Cell>
                                <Table.Cell>{formatDate(referral.created_at)}</Table.Cell>
                                <Table.Cell>{formatCurrency(referral.earned)}</Table.Cell>
                                <Table.Cell>{getCurrentPrice(referral)}</Table.Cell>
                                <Table.Cell>{getExpiryDate(referral)}</Table.Cell>
                                <Table.Cell>
                                    {#if referral.used_by.latest_offer?.listing?.status === 'active'}
                                        <Button
                                            on:click="{() => openPriceModal(referral)}"
                                            variant="default"
                                            size="sm"
                                            class="text-white"
                                        >
                                            Update Listing
                                        </Button>
                                    {:else}
                                        <Button
                                            on:click="{() => openPriceModal(referral)}"
                                            variant="default"
                                            size="sm"
                                            class="text-white"
                                        >
                                            List for Sale
                                        </Button>
                                    {/if}
                                </Table.Cell>
                            </Table.Row>
                        {/each}
                    </Table.Body>
                </Table.Root>
            </div>
        {:else}
            <p class="text-center text-gray-600 dark:text-gray-400">There are no referrals to show.</p>
        {/if}
    </section>
</div>

<Dialog.Root open="{selectedReferral !== null}" onOpenChange="{(open) => !open && (selectedReferral = null)}">
    <Dialog.Content class="sm:max-w-[425px]">
        <Dialog.Header>
            <Dialog.Title>
                {selectedReferral?.used_by.latest_offer?.listing?.status === 'active'
                    ? 'Update Listing for'
                    : 'Set price for'} {selectedReferral?.used_by.name}
            </Dialog.Title>
        </Dialog.Header>
        <form on:submit|preventDefault="{submitPrice}" class="space-y-4">
            <div class="space-y-4">
                <div class="space-y-2">
                    <Label for="price">Price</Label>
                    <Input id="price" type="number" bind:value="{selectedPrice}" min="0.01" step="0.01" required />
                </div>
                <div class="space-y-2">
                    <Label for="validity">Validity</Label>
                    <Popover.Root>
                        <Popover.Trigger asChild let:builder>
                            <Button
                                variant="outline"
                                class="{cn(
                                    'w-full justify-start text-left font-normal',
                                    !selectedValidity && 'text-muted-foreground'
                                )}"
                                builders="{[builder]}"
                            >
                                <CalendarIcon class="mr-2 h-4 w-4" />
                                {selectedValidity
                                    ? df.format(selectedValidity.toDate(getLocalTimeZone()))
                                    : 'Pick a date'}
                            </Button>
                        </Popover.Trigger>
                        <Popover.Content class="w-auto p-0">
                            <Calendar
                                bind:value="{selectedValidity}"
                                initialFocus
                                minDate="{today(getLocalTimeZone())}"
                            />
                        </Popover.Content>
                    </Popover.Root>
                </div>
            </div>
            <Dialog.Footer>
                <Button
                    type="submit"
                    variant="default"
                    disabled="{!isFormValid || isSubmitting}"
                    class="w-full text-white"
                >
                    {#if isSubmitting}
                        <svg
                            class="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"
                            ></circle>
                            <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                        Submitting...
                    {:else}
                        Submit
                    {/if}
                </Button>
            </Dialog.Footer>
        </form>
    </Dialog.Content>
</Dialog.Root>